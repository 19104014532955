<template>
    <span v-bind="attributes">
        <slot />
    </span>
</template>

<script>
export default {
    props: {
        size: {
            type: String,
            required: false,
            default: () => "md",
        },
        color: {
            type: String,
            required: false,
            default: () => "default",
        },
    },
    data() {
        return {
            /**
             * The base classes for the component.
             */
            baseClasses: "inline-flex items-center rounded-full text-xs font-medium",
        };
    },
    computed: {
        colors() {
            return {
                default: "bg-gray-100 text-gray-800",
                primary: "bg-primary-600 text-gray-400",
                secondary: "bg-secondary-100 text-secondary-800",
                success: "bg-green-500 text-black",
                warning: "bg-yellow-500 text-black",
                danger: "bg-red-500 text-black",
                muted: "bg-gray-800 text-gray-300",
                gray: "bg-gray-900 text-gray-500",
            };
        },
        sizes() {
            return {
                md: " px-2.5 py-0.5",
            };
        },
        classes() {
            return `v-badge ${this.baseClasses} ${this.sizes[this.size]} ${this.colors[this.color]}`;
        },
        attributes() {
            return {
                class: this.classes,
                ...this.$attrs,
            };
        },
        listeners() {
            return {
                ...this.$listeners,
            };
        },
    },
};
</script>
